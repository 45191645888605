import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 871.000000 599.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,599.000000) scale(0.100000,-0.100000)">


<path d="M3360 5837 c0 -18 -49 -64 -84 -77 -27 -10 -28 -11 -12 -33 9 -12 24
-35 34 -51 31 -53 50 -68 72 -56 18 9 19 17 14 65 -5 44 -3 59 14 88 24 38 58
57 103 57 18 0 28 4 24 10 -10 16 -165 13 -165 -3z"/>
<path d="M5606 5842 c-2 -4 15 -14 38 -21 44 -13 77 -46 91 -91 4 -14 12 -30
17 -37 6 -7 6 -19 -1 -31 -7 -13 -7 -22 1 -30 9 -9 5 -12 -17 -13 -23 -1 -25
-3 -8 -6 24 -5 83 22 83 37 0 5 17 24 38 43 113 103 109 99 82 93 -14 -2 -41
-9 -61 -14 -44 -11 -60 -3 -89 43 -22 35 -22 35 -95 35 -41 0 -76 -4 -79 -8z"/>
<path d="M3605 5827 c-23 -17 -14 -67 13 -67 34 0 43 14 31 48 -12 36 -17 38
-44 19z"/>
<path d="M4538 5833 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5482 5798 c2 -15 11 -24 26 -26 24 -4 30 16 10 36 -20 20 -40 14
-36 -10z"/>
<path d="M3698 5779 c-32 -18 -19 -29 32 -29 38 0 50 4 50 15 0 8 -9 15 -19
15 -11 0 -25 2 -33 5 -7 2 -21 0 -30 -6z"/>
<path d="M5360 5765 c0 -11 11 -15 39 -15 25 0 41 5 45 15 4 12 -4 15 -39 15
-33 0 -45 -4 -45 -15z"/>
<path d="M4551 5744 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3776 5725 c-11 -8 -17 -18 -14 -21 11 -10 48 7 48 22 0 18 -10 17
-34 -1z"/>
<path d="M2960 5609 c0 -29 6 -51 15 -59 8 -7 15 -28 15 -47 0 -24 8 -41 28
-59 l27 -26 -1 -373 c-2 -325 -4 -377 -18 -397 -22 -30 -22 -158 0 -199 12
-24 14 -55 11 -157 -3 -70 -9 -158 -15 -196 -8 -60 -7 -74 9 -110 16 -35 19
-67 19 -236 0 -303 15 -417 80 -610 24 -69 123 -259 152 -290 12 -12 36 -46
90 -125 26 -37 83 -95 94 -95 7 0 21 -11 30 -25 9 -14 20 -22 25 -19 5 3 9 0
9 -5 0 -6 6 -11 13 -11 7 0 20 -9 30 -20 18 -21 37 -26 37 -10 0 6 -4 10 -10
10 -5 0 -7 7 -4 15 6 14 -5 19 -33 16 -8 -1 -13 9 -13 24 0 20 -6 27 -30 32
-21 4 -30 11 -28 22 3 17 -19 53 -62 101 -85 95 -202 326 -255 500 -45 149
-46 167 -45 721 0 353 4 537 11 550 14 27 50 21 66 -11 10 -19 13 -132 13
-465 0 -242 5 -483 10 -536 16 -170 78 -367 138 -442 12 -16 22 -35 22 -43 0
-9 11 -30 25 -48 14 -18 22 -36 17 -40 -4 -4 -1 -6 6 -4 6 2 22 -15 35 -37 18
-31 29 -39 47 -37 13 1 26 4 28 7 2 2 4 56 3 119 -1 75 2 116 9 116 6 0 10 31
10 80 0 47 -4 80 -10 80 -6 0 -10 33 -10 80 0 47 4 80 10 80 6 0 10 18 10 40
0 54 27 56 32 3 2 -21 2 15 -1 80 -3 90 -1 117 9 117 9 0 10 3 1 13 -8 10 -7
17 1 25 9 9 8 12 -5 12 -9 0 -17 7 -17 15 0 17 16 20 25 6 6 -11 174 -9 193 2
20 13 14 44 -13 61 -25 16 -25 17 -25 167 l0 150 30 10 c34 12 39 38 11 56
-11 7 -49 13 -86 14 -36 1 -68 6 -71 10 -11 18 -22 8 -29 -27 -7 -39 4 -59 32
-59 14 0 17 -17 18 -135 2 -155 -2 -183 -21 -176 -7 3 -16 1 -19 -4 -7 -12
-57 -1 -67 13 -3 6 2 22 13 35 10 14 19 32 19 40 0 13 2 13 13 -2 11 -14 12 0
9 86 -4 113 -17 153 -66 198 l-30 27 22 23 c12 13 22 32 22 42 0 11 5 16 10
13 6 -4 10 8 10 29 0 20 -4 36 -10 36 -6 0 -10 93 -10 260 0 221 2 260 15 260
11 0 15 -18 18 -82 1 -46 4 26 5 158 l2 242 -25 6 c-31 8 -33 36 -2 36 12 0
105 4 207 9 102 5 200 10 218 10 29 1 32 4 32 31 0 17 -6 33 -14 36 -7 3 -99
10 -202 15 -270 14 -272 14 -280 22 -4 4 -3 7 3 7 6 0 11 11 11 25 0 18 -6 25
-19 25 -11 0 -101 2 -200 5 -190 5 -231 14 -237 50 -2 11 -11 26 -20 33 -9 7
-16 25 -17 40 0 23 -5 27 -32 30 -18 2 -33 9 -33 15 0 7 -9 26 -20 42 l-20 30
0 -46z m553 -296 c7 -17 6 -29 -6 -47 -16 -24 -21 -25 -144 -28 -82 -3 -140
-9 -161 -18 -43 -19 -66 -5 -70 43 -6 67 9 72 202 72 165 0 169 -1 179 -22z
m-73 -1165 c12 -14 18 -28 14 -38 -7 -15 -8 -29 -3 -88 1 -21 -5 -43 -18 -60
-49 -65 -103 -20 -103 85 0 64 13 108 29 98 5 -3 11 2 15 10 8 22 42 18 66 -7z
m115 -369 c-5 -14 -1 -25 12 -34 10 -7 15 -17 10 -21 -4 -4 -12 -3 -18 3 -6 6
-17 9 -24 7 -7 -3 -15 1 -18 10 -3 8 1 17 9 20 8 3 14 12 14 21 0 8 5 15 11
15 6 0 8 -9 4 -21z m52 -11 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0
-25z m-17 2 c0 -11 -4 -20 -9 -20 -14 0 -23 18 -16 30 10 17 25 11 25 -10z"/>
<path d="M6144 5623 c-22 -69 -25 -72 -55 -66 -22 4 -29 2 -29 -11 0 -9 5 -16
10 -16 6 0 10 -4 10 -10 0 -5 -7 -10 -16 -10 -12 0 -14 -7 -9 -28 4 -19 1 -33
-9 -41 -8 -7 -76 -16 -168 -21 -84 -6 -170 -12 -191 -15 -33 -5 -40 -2 -44 15
-4 14 -14 20 -34 20 -34 0 -41 -29 -9 -37 22 -6 27 -29 8 -36 -7 -2 -11 -7 -8
-10 7 -7 233 -27 307 -27 33 0 64 -5 71 -12 18 -18 15 -93 -4 -109 -13 -11
-23 -9 -60 10 -39 21 -53 23 -136 19 -58 -3 -93 -9 -96 -16 -2 -7 -13 -12 -23
-12 -11 0 -17 -4 -14 -10 4 -6 -8 -10 -28 -10 l-34 0 9 -57 c5 -32 7 -74 4
-93 -3 -20 -6 -210 -6 -423 -1 -332 -3 -387 -15 -387 -8 0 -15 -4 -15 -9 0
-14 -150 -30 -292 -31 -46 0 -59 -4 -68 -20 -9 -16 -6 -22 21 -35 l31 -16 -4
-134 c-3 -125 -4 -135 -27 -159 -20 -21 -34 -26 -72 -26 -56 0 -88 22 -97 68
-4 17 -6 83 -4 146 3 110 4 114 28 125 47 21 22 36 -66 39 -90 3 -120 -8 -86
-33 10 -8 24 -16 30 -18 7 -2 13 -56 16 -150 5 -138 6 -147 30 -172 44 -47 76
-58 155 -53 61 4 78 9 109 34 46 36 56 72 56 196 0 110 10 148 39 148 34 0 41
-32 41 -178 l0 -140 -30 -11 c-20 -6 -30 -17 -30 -30 0 -24 12 -28 133 -36 62
-5 90 -11 94 -21 3 -9 20 -14 44 -14 37 0 51 -12 29 -25 -5 -3 -10 -42 -10
-86 0 -59 -3 -79 -13 -79 -11 0 -15 -55 -20 -277 -4 -176 -2 -280 4 -284 5 -3
7 -13 3 -23 -4 -11 0 -22 12 -30 17 -13 17 -15 0 -41 -10 -15 -16 -29 -13 -31
12 -12 53 84 48 114 -1 6 3 12 9 12 6 0 8 -5 4 -11 -4 -7 -2 -10 5 -8 7 3 12
8 12 12 -1 4 10 18 24 30 14 12 26 33 28 46 2 13 10 29 18 35 24 19 68 118 73
165 2 24 9 47 13 50 18 12 29 50 52 181 5 30 12 271 15 535 3 264 9 498 13
520 7 33 13 41 34 43 47 5 49 -19 49 -550 0 -446 -2 -499 -19 -586 -11 -54
-22 -109 -23 -123 -2 -15 -9 -33 -15 -40 -6 -8 -16 -39 -24 -69 -12 -49 -73
-180 -117 -250 -9 -14 -28 -49 -44 -79 -16 -30 -42 -67 -58 -83 -17 -15 -30
-33 -30 -40 0 -6 -16 -27 -36 -46 -20 -19 -37 -46 -38 -59 -2 -14 -9 -29 -17
-34 -11 -7 -8 -9 9 -9 12 0 26 8 29 18 9 23 34 41 50 35 7 -3 13 1 13 9 0 8
10 24 23 35 12 11 33 35 47 53 14 18 46 58 71 89 110 135 184 300 230 511 16
74 22 151 28 355 6 193 11 266 22 282 10 17 11 31 3 61 -8 30 -7 53 3 91 11
45 11 56 -2 81 -18 35 -20 171 -4 202 16 29 18 235 3 273 -18 46 -37 363 -29
463 4 48 9 123 10 167 2 96 16 129 51 122 17 -3 22 -1 15 7 -14 17 -17 92 -4
108 15 19 26 98 13 98 -6 0 -13 -8 -16 -17z m-537 -715 c-2 -24 -4 -5 -4 42 0
47 2 66 4 43 2 -24 2 -62 0 -85z m53 -714 c0 -8 5 -14 11 -14 5 0 23 -15 40
-32 32 -36 38 -81 18 -149 -9 -30 -9 -42 1 -54 18 -21 24 -89 10 -114 -14 -26
-36 -27 -77 -1 -25 15 -33 27 -33 49 0 16 -6 31 -13 34 -10 4 -14 -8 -16 -41
-1 -25 -4 -49 -7 -54 -3 -4 -27 -8 -55 -8 l-49 0 0 59 c0 65 9 74 62 60 35 -9
42 2 28 45 -8 26 -17 35 -40 38 -16 3 -34 0 -40 -6 -8 -8 -10 8 -8 54 2 53 6
65 21 68 34 7 62 -7 80 -38 19 -32 45 -33 28 -1 -6 10 -6 33 -1 57 7 30 6 41
-5 50 -12 9 -9 11 15 12 20 0 30 -4 30 -14z m-5 -473 c-18 -7 -55 -4 -55 5 0
2 17 4 38 3 28 0 32 -2 17 -8z"/>
<path d="M4032 5588 c-17 -13 -31 -29 -30 -37 3 -16 92 -41 109 -31 16 10 1
70 -17 70 -8 0 -14 5 -14 10 0 15 -12 12 -48 -12z"/>
<path d="M4660 5575 c0 -29 3 -30 25 -9 13 14 14 19 3 25 -22 14 -28 10 -28
-16z"/>
<path d="M5023 5594 c-7 -3 -13 -16 -13 -29 0 -18 7 -24 35 -29 37 -7 75 0 75
13 0 25 -69 56 -97 45z"/>
<path d="M4430 5580 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M4545 5531 c-3 -12 -11 -21 -16 -21 -15 0 -10 -50 6 -56 25 -10 83 5
81 20 -1 8 -16 29 -33 46 -30 30 -31 31 -38 11z"/>
<path d="M5385 5329 c-49 -4 -131 -7 -181 -8 -98 -1 -121 -12 -109 -50 5 -17
19 -20 143 -25 75 -3 167 -8 205 -10 l67 -3 0 53 c0 45 -3 54 -17 52 -10 0
-58 -4 -108 -9z"/>
<path d="M5520 5239 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2606 4525 c-21 -32 -20 -54 3 -107 22 -52 44 -73 95 -90 22 -7 44
-20 49 -29 13 -20 1 -69 -16 -69 -16 0 -67 -52 -67 -68 0 -16 27 -15 37 1 4 7
25 23 46 36 20 12 37 27 37 32 0 4 7 6 15 3 8 -4 15 -1 15 5 0 6 7 11 16 11
14 0 16 8 10 52 -4 29 -12 58 -18 65 -5 7 -7 17 -3 23 4 6 -9 10 -35 10 -61 0
-120 41 -120 84 0 12 -11 31 -24 43 l-24 23 -16 -25z"/>
<path d="M6482 4518 c-6 -8 -15 -25 -21 -40 -13 -34 -95 -91 -120 -83 -23 7
-43 -17 -39 -47 2 -16 -4 -26 -20 -33 -16 -7 -22 -17 -20 -35 3 -21 9 -25 40
-28 29 -3 44 -12 75 -49 21 -26 41 -51 45 -57 5 -8 11 -7 19 1 8 8 8 18 -2 38
-8 15 -18 27 -22 26 -18 -5 -48 43 -42 67 7 29 28 52 48 52 23 0 65 38 82 74
18 37 17 117 -1 123 -6 2 -16 -2 -22 -9z"/>
<path d="M3600 4212 c0 -6 -8 -13 -17 -15 -16 -4 -16 -5 -1 -6 18 -1 34 16 24
26 -3 4 -6 1 -6 -5z"/>
<path d="M3946 4173 c-33 -8 -98 -78 -112 -119 -30 -86 -14 -184 40 -242 71
-79 214 -84 293 -10 53 50 50 100 -4 61 -68 -49 -76 -52 -125 -48 -80 5 -118
58 -118 163 0 69 36 138 78 149 63 16 114 -5 137 -57 36 -81 73 -54 56 41 -6
34 -13 64 -16 67 -3 3 -16 0 -30 -6 -17 -8 -33 -8 -51 -2 -26 10 -113 12 -148
3z"/>
<path d="M4220 4165 c0 -9 11 -20 24 -25 23 -8 24 -14 31 -143 8 -160 3 -193
-31 -201 -15 -4 -24 -14 -24 -26 0 -20 6 -20 162 -18 l163 3 19 53 c25 69 9
92 -26 38 -29 -45 -61 -58 -129 -54 l-54 3 -3 73 -3 72 56 0 c50 0 57 -3 67
-25 19 -43 33 -22 36 53 2 56 0 72 -11 72 -8 0 -19 -11 -25 -24 -8 -19 -20
-25 -57 -28 -26 -2 -51 -1 -56 3 -6 3 -9 37 -7 75 l3 69 70 0 c69 0 70 0 85
-32 27 -57 51 -35 40 37 l-5 35 -162 3 c-142 2 -163 0 -163 -13z"/>
<path d="M4703 4173 c-32 -6 -73 -59 -73 -93 0 -56 18 -78 107 -137 83 -55 88
-60 88 -93 0 -28 -5 -37 -28 -48 -47 -23 -96 1 -125 61 -32 66 -51 51 -48 -38
1 -73 6 -81 34 -64 17 11 30 11 72 0 45 -11 57 -11 93 3 52 21 82 59 82 108 0
51 -29 86 -106 130 -77 44 -99 65 -99 93 0 73 99 52 125 -26 7 -19 19 -35 28
-37 23 -5 31 37 20 95 -7 40 -12 47 -28 45 -24 -4 -39 -4 -85 2 -19 2 -45 2
-57 -1z"/>
<path d="M6420 3546 c0 -14 5 -26 10 -26 6 0 10 9 10 19 0 11 -4 23 -10 26 -6
4 -10 -5 -10 -19z"/>
<path d="M6392 3419 c-48 -96 -53 -148 -21 -205 l17 -32 -31 2 c-29 1 -32 -2
-35 -32 -3 -28 -6 -31 -27 -26 -36 9 -39 -13 -7 -44 61 -56 87 -71 105 -61 14
8 17 5 17 -19 0 -27 -5 -31 -49 -46 -28 -9 -57 -16 -65 -16 -24 0 -19 -19 7
-29 l22 -9 -25 -10 c-22 -9 -23 -11 -7 -17 9 -4 17 -15 17 -24 0 -14 2 -14 10
-1 7 12 16 12 50 4 33 -9 46 -8 74 5 29 14 96 94 96 115 0 4 -10 16 -22 26
l-22 18 27 53 c16 30 37 66 49 81 45 58 -9 62 -114 8 -11 -6 -20 -4 -28 5 -13
16 -13 11 0 175 15 175 12 180 -38 79z m-52 -539 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M2686 3483 c-10 -11 -7 -211 4 -218 14 -8 13 -88 -1 -102 -9 -9 -24
-7 -61 8 -94 37 -111 14 -48 -61 46 -54 53 -89 26 -119 -23 -25 -20 -35 19
-77 19 -20 35 -41 35 -45 0 -5 9 -9 19 -9 11 0 23 -4 26 -10 3 -5 33 -10 66
-10 47 0 59 3 59 16 0 11 6 14 21 9 17 -6 20 -3 17 12 -2 10 -13 19 -26 21
-12 2 -20 7 -17 12 9 15 -17 23 -27 9 -7 -10 -8 -8 -5 8 3 12 -1 30 -8 40 -13
17 -14 17 -20 1 -8 -19 -50 -25 -60 -8 -4 6 -4 24 0 40 6 26 10 29 31 24 19
-5 29 -1 40 15 8 11 21 21 29 21 18 0 55 48 55 72 0 10 -7 21 -15 24 -8 4 -15
12 -15 19 0 7 -12 17 -27 23 -26 8 -28 12 -28 69 0 48 -5 68 -22 92 -13 16
-23 42 -23 57 0 27 -33 78 -44 67z"/>
<path d="M2860 3064 c0 -3 6 -12 14 -20 16 -15 32 -2 21 16 -7 11 -35 14 -35
4z"/>
<path d="M6248 2953 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6240 2890 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3552 2829 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
3 -12 -1 -9 -9z"/>
<path d="M5565 2830 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M2695 2760 c-17 -27 47 -109 86 -110 9 0 19 -9 22 -20 7 -27 24 -25
31 4 3 13 24 35 46 50 22 14 40 31 40 36 0 20 -32 22 -65 4 -38 -20 -55 -14
-34 12 13 16 12 16 -9 5 -26 -14 -58 -7 -66 14 -6 17 -42 20 -51 5z"/>
<path d="M6377 2755 c-9 -8 -30 -15 -48 -15 -28 0 -30 -2 -18 -16 12 -15 10
-16 -19 -10 -30 7 -41 -1 -25 -18 4 -4 8 -15 8 -24 0 -13 10 -18 40 -20 36 -3
46 2 78 35 24 23 37 45 35 57 -4 26 -31 32 -51 11z"/>
<path d="M3033 2713 c-7 -2 -13 -14 -13 -25 0 -27 30 -20 41 10 8 22 0 27 -28
15z"/>
<path d="M6056 2712 c-7 -12 23 -52 39 -52 26 0 29 23 5 42 -24 20 -36 22 -44
10z"/>
<path d="M6205 2710 c-8 -13 5 -13 25 0 13 8 13 10 -2 10 -9 0 -20 -4 -23 -10z"/>
<path d="M6120 2560 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6300 2524 c-21 -21 -22 -24 -7 -24 23 0 57 18 57 29 0 20 -27 17
-50 -5z"/>
<path d="M2780 2526 c0 -9 8 -16 17 -16 9 0 25 -7 35 -17 16 -14 18 -14 18 -1
0 18 -16 33 -47 42 -17 6 -23 3 -23 -8z"/>
<path d="M2906 2433 c-3 -3 -6 -19 -6 -35 0 -38 32 -38 59 1 12 16 21 31 21
33 0 7 -67 8 -74 1z"/>
<path d="M6140 2428 c0 -22 22 -38 53 -38 24 0 28 4 25 23 -2 18 -10 23 -40
25 -27 2 -38 -1 -38 -10z"/>
<path d="M3066 2261 c-3 -5 1 -14 8 -20 16 -13 32 2 21 19 -8 12 -22 13 -29 1z"/>
<path d="M3220 2100 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M4480 1735 c0 -28 17 -32 46 -11 21 16 22 14 -31 31 -11 3 -15 -3
-15 -20z"/>
<path d="M4585 1740 c-3 -6 6 -15 20 -20 34 -13 32 -13 38 10 4 17 0 20 -23
20 -16 0 -32 -5 -35 -10z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
